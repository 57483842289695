<template>
  <div style="padding: 10px">
    <div style="background: #fff; border-radius: 8px; padding: 20px;">
      <div class="query-c">
        <!-- 查询： -->
        <!-- <Input placeholder="请输入用户名称" v-model="name" style="width: auto;margin-right:10px;" /> -->
        <!-- <Input placeholder="请输入用户手机号" v-model="phone" style="width: auto;margin-right:10px;" /> -->
        <!-- <DatePicker v-model="date" format="yyyy-MM-dd hh:mm:ss" @on-change="changeDate" type="daterange"
          placement="bottom-end" placeholder="Select date" style="width: 200px;margin-right:10px;" /> -->
          <Button type="primary" size="small" @click="showInfoDialog">新增</Button>
          <!-- <Button type="primary" size="small" @click="getFruitList">查询</Button> -->
      </div>
      <br>
      <Table max-height="670" border stripe :columns="columns" :data="data">
        <template #main_image_url="{ row }">
          <div>
            <img :src="`https://${row.main_image_url}`" :fit="'cover'" width="100px" height="100px"  />
          </div>
        </template>
        <template #price="{ row }">
          <div>{{ row.price }}</div>
        </template>
        <template #createdAt="{ row }">
          <div>{{ timeFormatter(row.createdAt, '{year}-{month}-{day} {hour}:{minute}:{second}') }}</div>
        </template>
        <template #updatedAt="{ row }">
          <div>{{ timeFormatter(row.updatedAt, '{year}-{month}-{day} {hour}:{minute}:{second}') }}</div>
        </template>
        <template #action="{ row, index }">
          <div>
            <Button class="operate-btn" type="primary" size="small"
              @click="showInfoDialog({ row, index })">编辑</Button>
            <Button class="operate-btn" type="error" size="small"
              @click="deleteItem({ row, index })">删除</Button>
          </div>
        </template>
      </Table>
      <br>
      <Page :total="total" :page-size="pageSize" show-elevator @on-change="changePage" />
    </div>

    <Modal v-model="fruitVisible" title="水果信息" width="960">
      <Form ref="fruitInfo" :model="fruitInfo" :label-width="100" :rules="ruleInline">
        <FormItem prop="name" label="水果名称">
          <Input type="text" v-model="fruitInfo.name" placeholder="水果名称" />
        </FormItem>
        <FormItem prop="description" label="水果描述">
          <Input type="textarea" v-model="fruitInfo.description" placeholder="水果描述" />
          <!-- <editor :value="fruitInfo.description" toolbarId="description"
          editorRef="description" @change="editorChange($event,'description')"></editor> -->
        </FormItem>
        <FormItem prop="fileList" label="展示图">
          <upload @uploadSuccess="uploadSuccess" :limit="3" @uploadRemove="uploadRemove"
            :fileList="fileList"></upload>
        </FormItem>
        <FormItem prop="details" label="图文详情">
          <!-- <Input type="textarea" v-model="fruitInfo.details" placeholder="图文详情" /> -->
          <editor :value="fruitInfo.details" toolbarId="details" editorRef="details"
               @change="editorChange($event,'details')"></editor>
        </FormItem>
        <FormItem prop="price" label="价格（元）">
          <Input type="text" v-model="fruitInfo.price" placeholder="价格（元）" />
        </FormItem>
        <FormItem prop="remaining_stock" label="剩余库存">
          <Input type="number" v-model="fruitInfo.remaining_stock" placeholder="剩余库存" />
        </FormItem>
        <FormItem prop="total_stock" label="总库存">
          <Input type="number" v-model="fruitInfo.total_stock" placeholder="总库存" />
        </FormItem>
      </Form>
      <template #footer>
        <Button  size="small"  @click="cancel">取消</Button>
        <Button type="primary" size="small"  @click="updateFruit">确认</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { getFruitList, createFruit, updateFruit, deleteFruitById } from '@/api'
import { formatterPrice, timeFormatter } from '@/utils'
import upload from '@/components/upload'
import editor from '@/components/editor'

export default {
    name: 'fruit',
    components: {
        upload,
        editor,
    },
    data() {
        const validatePrice = (rule, value, callback) => {
            console.log(value)
            if (/^(\+|-)?\d+(\.\d{1,2})?$/.test(value)) {
                callback()
            } else {
                return callback(new Error('价格格式有误,最多精确到分（2位小数）！'))
            }
        }
        const validateFile = (rule, value, callback) => {
            console.log(value)
            if (!this.fruitInfo.main_image_url || !this.fruitInfo.sub_image_url1 || !this.fruitInfo.sub_image_url2) {
                return callback(new Error('请上传3张展示图！'))
            }
            callback()
        }
        const validateStock = (rule, value, callback) => {
            console.log(value)
            if ((this.fruitInfo.remaining_stock != 0 || this.fruitInfo.total_stock != 0)
             && (!this.fruitInfo.remaining_stock || !this.fruitInfo.total_stock)) {
                return callback(new Error('库存数不能为空'))
            }
            if (+this.fruitInfo.remaining_stock > +this.fruitInfo.total_stock) {
                return callback(new Error('剩余库存不能超过总库存'))
            }
            if (this.fruitInfo.remaining_stock < 0 || this.fruitInfo.total_stock < 0) {
                return callback(new Error('库存数应为大于0的整数'))
            }
            callback()
        }
        const validateDetails = (rule, value, callback) => {
            console.log(this.fruitInfo)
            if (!this.fruitInfo?.details?.length) {
                return callback(new Error('图文详情不能为空'))
            }
            callback()
        }
        return {
            columns: [
                {
                    title: '序号',
                    width: 80,
                    key: 'index',
                },
                {
                    title: '水果名称',
                    key: 'name',
                },
                {
                    title: '主图',
                    slot: 'main_image_url',
                    width: 140,
                },
                {
                    title: '价格（元）',
                    slot: 'price',
                },
                {
                    title: '剩余库存',
                    key: 'remaining_stock',
                },
                {
                    title: '总库存',
                    key: 'total_stock',
                },
                {
                    title: '创建时间',
                    slot: 'createdAt',
                    width: 200,
                },
                {
                    title: '更新时间',
                    slot: 'updatedAt',
                    width: 200,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 150,
                    align: 'center',
                },
            ],
            data: [],
            total: 0,
            ruleInline: {
                name: [{ required: true, message: '不能为空', trigger: 'blur' }],
                description: [{ required: true, message: '描述不能为空', trigger: 'blur' }],
                fileList: [{ required: true, validator: validateFile, trigger: 'blur' }],
                price: [{ required: true, validator: validatePrice, trigger: 'blur' }],
                remaining_stock: [{ required: true, validator: validateStock, trigger: 'blur' }],
                total_stock: [{ required: true, validator: validateStock, trigger: 'blur' }],
                details: [{ required: true, validator: validateDetails, trigger: 'blur' }],
            },
            fruitInfo: {},
            fruitVisible: false,
            page: 1,
            pageSize: 10,
            phone: '',
            name: '',
            fileList: [],
            date: [new Date(new Date().getTime() - 3600000 * 24 * 30), new Date()],
        }
    },
    mounted() {
        this.getFruitList()
    },
    methods: {
        timeFormatter(date, fmt) {
            return timeFormatter(date, fmt)
        },
        changeDate(date) {
            this.date = date
        },
        changePage(page) {
            console.log(page)
            this.page = page
            this.getFruitList()
        },
        async getFruitList() {
            const param = {
                page: this.page,
                pageSize: this.pageSize,
                // startDate: this.date[0],
                // endDate: this.date[1],
                name: this.name,
                // phone: this.phone,
            }
            const { success, data: { data, total } } = await getFruitList(param)
            if (success) {
                this.total = total
                this.data = data.map((i, idx) => {
                    i.index = (this.page - 1) * this.pageSize + idx + 1
                    i.price = formatterPrice(i.price)
                    return i
                })
            }
        },
        showInfoDialog({ row, index }) {
            console.log(index)
            this.$refs.fruitInfo.resetFields()
            this.fileList = []
            this.fruitVisible = true
            this.fruitInfo = row ? JSON.parse(JSON.stringify(row)) : {}
            if (this.fruitInfo.main_image_url) {
                this.fileList.push({ status: 'finished', image_url: this.fruitInfo.main_image_url })
            }
            if (this.fruitInfo.sub_image_url1) {
                this.fileList.push({ status: 'finished', image_url: this.fruitInfo.sub_image_url1 })
            }
            if (this.fruitInfo.sub_image_url2) {
                this.fileList.push({ status: 'finished', image_url: this.fruitInfo.sub_image_url2 })
            }
        },
        cancel() {
            this.fruitInfo = {}
            this.fileList = []
            this.fruitVisible = false
        },
        deleteItem({ row, index }) {
            console.log(index)
            console.log(row)
            console.log(this.$Modal)
            this.$Modal.confirm({
                title: '确认删除',
                content: '<p>确认删除</p>',
                onOk: async () => {
                    const param = {
                        id: row.id,
                    }
                    const { success, data } = await deleteFruitById(param)
                    if (success) {
                        console.log(data)
                        this.getFruitList()
                    }
                    this.$Message.info('操作成功')
                },
                onCancel: () => {
                    this.fruitInfo = {}
                },
            })
        },
        updateFruit() {
            this.$refs.fruitInfo.validate(async (valid) => {
                if (valid) {
                    const param = {
                        name: this.fruitInfo.name,
                        description: this.fruitInfo.description,
                        main_image_url: this.fileList[0]?.image_url ?? '',
                        sub_image_url1: this.fileList[1]?.image_url ?? '',
                        sub_image_url2: this.fileList[2]?.image_url ?? '',
                        details: this.fruitInfo.details,
                        price: this.fruitInfo.price * 100,
                        remaining_stock: this.fruitInfo.remaining_stock,
                        total_stock: this.fruitInfo.total_stock,
                    }
                    if (this.fruitInfo.id) {
                        param.id = this.fruitInfo.id
                    }
                    const { success, data } = this.fruitInfo.id ? await updateFruit(param).catch(() => {
                        this.$Message.error('图文详情内容超出最大长度！')
                    }) : await createFruit(param).catch(() => {
                        this.$Message.error('图文详情内容超出最大长度！')
                    })
                    if (success) {
                        console.log(data)
                        this.fruitInfo = {}
                        this.fileList = []
                        this.fruitVisible = false
                        this.getFruitList()
                    }
                }
            })
        },
        uploadSuccess(list) {
            console.log(list)
            this.$set(this.fruitInfo, 'main_image_url', list[0].image_url ?? '')
            this.$set(this.fruitInfo, 'sub_image_url1', list[1]?.image_url ?? '')
            this.$set(this.fruitInfo, 'sub_image_url2', list[2]?.image_url ?? '')
        },
        uploadRemove(file) {
            console.log(file)
            this.fileList.splice(this.fileList.indexOf(file), 1)
        },
        editorChange({ html }, prop) {
            this.fruitInfo[prop] = html
        },
    },
}
</script>

<style scoped>
.operate-btn {
  margin-bottom: 10px;

}


.operate-btn+.operate-btn {
  margin-left: 5px;
}
</style>
